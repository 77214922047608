import { AppstoreOutlined, BarChartOutlined, CalendarOutlined, CloseOutlined, MenuOutlined, PicLeftOutlined, PushpinFilled, PushpinOutlined, SelectOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Layout, message, Radio, Row, Select, Space, Spin, Table, Tooltip, Typography } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';
import { useHistory, useLocation } from "react-router-dom";

import Bookmark from "../components/Bookmark";
import BrowseFilters5 from "../components/BrowseFilters5";
import CopyISBNs from "../components/CopyISBNs";
import ItemLayouts from "../components/ItemLayouts";
import ItemSales2 from "../components/ItemSales2";
import Order from "../components/Order";
import Overlay from "../components/Overlay";
import Paginate from "../components/Paginate";
import Refine from "../components/Refine";
import Renderer from "../components/Renderer";
import SalesSummary from "../components/SalesSummary";
import TestOrderBox from "../components/TestOrderBox";
import Share from '../components/title/Share';
import LayoutHeader from "../layouts/partials/LayoutHeader";
import nocover from "../media/no-cover.png";
import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import { usePrefs } from "../utils/Prefs";
import { useSession } from "../utils/Session";
import { bestsellerColor, drawQuantity, futureColor, handleTermSlash, makeKey, objectToPath, parseSubjectsWeird, pathToObject, peerColor, returnedColor, successColor, toUriHash, warningColor } from "../utils/Utils";
import Pins from "./Pins";
import TitleRank from '../components/title/TitleRank';
import ListInclude from '../components/ListInclude';

export default function BrowsePage4() {

    const path = "/purchasing/browse";
    const location = useLocation();
    const history = useHistory();
    const [session, setSession] = useSession();
    const [results, setResults] = useState({ filter_info: [], rows: [], toolboxes: {}, max_offset: 0 })
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [display, setDisplay] = useState((Cookies.get("browse_show_list")) ? Cookies.get("browse_show_list") : "details2");
    const [expanded, setExpanded] = useState([]);
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname, "browse_limit"))
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [change, setChange] = useState({})
    const [title, setTitle] = useState("Browse");
    const [initLoaded, setInitLoaded] = useState(false);
    const [prefs, setPrefs] = usePrefs();
    const [listLabel, setListLabel] = useState({ key: "", value: "" })
    const [listID, setListID] = useState("");

    const [toolboxes, setToolboxes] = useState({});

    const canSequence = () => {


        if (!searchFilters.hasOwnProperty("x")) {
            return false;
        }
        if (!searchFilters.x) {
            return false;
        }

        return true;
    }

    function completeSuggestedCart() {
        let args = {
            store_id: session.corp_id,
            cart_id: results.context.list_id,
            done: true,
        };
        apiCall("suggested_cart/toggleDone", args, (_status, _result) => {
            if (_status) {
                window.open("/purchasing/suggested-carts", "_self");
            }
        })
    }

    const getHeader = () => {
        // No context, do nothing
        if (!results.hasOwnProperty("context")) {
            return (
                <Space>
                    <div>Browse</div>
                    <Share store_path={location.pathname.substring(1, location.pathname.length)} >
                        <Button size='small'>
                            <small>Share</small>
                        </Button>
                    </Share>
                </Space>
            );
        }
        let {
            name = "",
            list_id = "",
            header = "",
            type = "",
            negated = false,

        } = results.context;


        let _x = pathToObject(location.pathname);
        let _share_id = ""
        if (_x.hasOwnProperty("x") && Array.isArray(_x.x) && _x.x.length > 0 && _x.x[0].substr(0, 1) !== "-") {
            _share_id = _x.x[0];
        }


        if (negated) {
            return (
                <Space>
                    <div>Browse</div>
                    <Share store_path={location.pathname.substring(1, location.pathname.length)} >
                        <Button size='small'>
                            <small>Share</small>
                        </Button>
                    </Share>
                </Space>
            );
        }










        // LIST, CATALOGUE, CART SUGGESTION, TEMP
        // eslint-disable-next-line default-case
        switch (type) {
            case "list":
                // setListLabel({ [searchFilters.x]: name });
                return (
                    <div>Browsing list:&nbsp;
                        <Space>
                            <span onClick={() => window.open("/purchasing/my-lists/filter/t/" + list_id, "_blank")} className="browse-list-title">{name} ({results.row_count} titles) &nbsp;<SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} />
                            </span>
                            <Share customer_path={(_share_id) ? "lists/" + _share_id : ""} store_path={"biz/purchasing/browse/filter/x/" + list_id + "/v/sequence"} >
                                <Button size='small'>
                                    <small>Share</small>
                                </Button>
                            </Share>
                        </Space>
                    </div>
                )
            case "catalogue":
                return (
                    <div>Browsing catalogue:
                        <Space>
                            <span onClick={() => window.open("/purchasing/catalogues/filter/t/" + list_id, "_blank")} className="browse-list-title">
                                {name} ({results.row_count} titles) &nbsp;<SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} />
                            </span>
                            <Share store_path={"biz/purchasing/browse/filter/x/" + list_id + "/v/sequence"} >
                                <Button size='small'>
                                    <small>Share</small>
                                </Button>
                            </Share>
                        </Space>
                    </div>
                )
            case "analytics":
                return (
                    <Space>
                        <div>Browsing {name}</div>
                        <Share store_path={location.pathname.substring(1, location.pathname.length)} >
                            <Button size='small'>
                                <small>Share</small>
                            </Button>
                        </Share>
                    </Space>
                )
            case "cart_suggestion":
                return (
                    <Space>
                        <div>Browsing {name}</div>
                        <Button type='primary' size='small' onClick={() => completeSuggestedCart()}>
                            Complete Suggested Cart
                        </Button>
                    </Space>
                )
            case "temporary":
                return (
                    <Space>
                        <div>Browsing {name}</div><Share store_path={location.pathname.substring(1, location.pathname.length)} >
                            <Button size='small'>
                                <small>Share</small>
                            </Button>
                        </Share>
                    </Space>
                )
        }

        // SERIES 
        if (searchFilters.hasOwnProperty("k") && searchFilters.k === "series") {
            let _term = searchFilters.hasOwnProperty("t") ? searchFilters.t : "";
            return (<>Browse
                <Space>
                    <span style={{ "fontWeight": "normal", "color": "#666", "fontSize": "13px" }}> - {_term} (series)</span>
                    <Share customer_path={(_share_id) ? "lists/" + _share_id : ""} store_path={"biz/purchasing/browse/filter/x/" + list_id + "/v/sequence"} >
                        <Button size='small'>
                            <small>Share</small>
                        </Button>
                    </Share>
                </Space>
            </>)
        }

        // PRESET 
        let m = pathToObject(location.pathname);
        if (m.hasOwnProperty("p")) {
            return (<>Browse  <span style={{ "fontWeight": "normal", "color": "#666", "fontSize": "13px" }}>Preset</span></>)
        }


        return (
            <Space>
                <div>Browse</div>
                <Share store_path={location.pathname.substring(1, location.pathname.length)} >
                    <Button size='small'>
                        <small>Share</small>
                    </Button>
                </Share>
            </Space>
        );


    }

    const setStateChange = (_e) => {
        getBrowse(_e)
    }

    const setSupplier = (_supplier) => {
        apiCall("session/setSupplier", { supplier: _supplier }, (_status, _result) => {
            if (_status) {
                setSession(_result);
                getBrowse();
            }
        })
    }


    const getBrowse = (_change = false, _obj = searchFilters, _route = true) => {
        if (loading) { return; }
        setLoading(true);
        let _filters = { ..._obj }
        if (_filters["t"]) {
            _filters["t"] = _filters["t"].replace("%25", "%");
            _filters["t"] = _filters["t"].replace("%2f", "/");
        }
        if (_change) {
            _filters["click_info"] = JSON.stringify(_change);
        }
        apiCall("browse/get", _filters, (_status, _result) => {
            if (_status) {
                // console.log(_result)
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                setFilters(_result.filter_info);
                setToolboxes(_result.toolboxes);
                setResults(_result);
                setExpanded([]);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                forceCheck();
                if (_change) {
                    updateFilters(_result.filter_info, _route);
                } else {
                    setLoading(false);
                }
                setInitLoaded(true);
            } else {
                message.error(_result.error);
                setLoading(false);
            }
        });
    }

    const [flag, setFlag] = useState(false);
    const routeFilters = (_sf, _route) => {
        let _filter_path = objectToPath(_sf);
        if (_route) {
            setFlag(true);
            history.push({ pathname: path + _filter_path })
        }
        setLoading(false);
    }

    const updateFilters = (_arr, _route, o = searchFilters.o, l = searchFilters.l, t = searchFilters.t, k = searchFilters.k, v = searchFilters.v, p = searchFilters.p, z = searchFilters.z) => {
        let _sf = {}
        _arr.forEach(element => {
            if (element.selected_rows.length > 0) {
                _sf[element.routing_char] = (element.exclude) ? element.selected_rows.map(itm => "-" + itm) : element.selected_rows;
            }
        });

        _sf.o = o;
        _sf.l = l;
        _sf.t = t;
        _sf.k = k;
        _sf.v = v;
        _sf.p = p;
        _sf.z = z;

        setSearchFilters(_sf);
        if (_route) {
            routeFilters(_sf, _route);
        } else {
            setLoading(false);
        }
    }


    // Call on back/forward button
    const [refresh, setRefresh] = useState(false)
    const onPopState = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        window.addEventListener('popstate', onPopState);
        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, []);


    const headerSearch = () => {
        setLoading(true);
        let obj = pathToObject(location.pathname, "browse_limit");
        setSearchFilters(obj);
        getBrowse(false, obj);
    }
    useEffect(headerSearch, [history.location.state, refresh])
    // const updateSearchWithin = () => {
    //     let obj = pathToObject(location.pathname, "browse_limit");
    //     if (obj.hasOwnProperty("t") && (obj.t)) {
    //         form.setFieldsValue({ "customer_search": handleTermSlash(obj.t, false) });
    //     }
    // }
    // useEffect(updateSearchWithin, [history.location.pathname])

    const colorDate = (_timestamp, values) => {
        let now = moment().unix();
        let color = "inherit";
        if (_timestamp > now) {
            // future
            color = "futureColor";
        } else {
            // within the last two months
            if ((now - _timestamp) < 5274000) {
                // within the last two months
                color = "returnedColor";
            }
        }
        if (values.highlight) {
            return color;
        }
        return color;
    }

    function colorOHOO(highlight) {
        let colour = "inherit"
        if (highlight) {
            return colour;
        }
        return peerColor;
    }

    const noCover = (e) => {

        e.target.src = nocover;
        e.target.onerror = null;
    }

    const convertSupplierCode = (_qty = "") => {

        _qty = _qty.trim();
        _qty = _qty.toUpperCase();

        if (_qty === 'T') {
            return "TOS";
        } else if (_qty === 'Y') {
            return "YES";
        } else if (_qty === '+') {
            return "NYR";
        } else if (_qty === "Q") {
            return "Qwk";
        } else if (_qty == "L") {
            return "LOW";
        } else if (_qty === "Z") {
            return "ITO";
        } else if (_qty === "R") {
            return "RP";
        } else if (_qty === "P") {
            return "POD";
        } else if (_qty === "M") {
            return "Mgd";
        } else if (_qty === 'O') {
            return 'BOO';
        }

        return _qty;
    }


    const drawPrice = (list_price_can = 0, list_price_usa = 0) => {

        let _can = (list_price_can > 0) ? "$" + list_price_can : "";
        let _us = (list_price_usa > 0) ? "$" + list_price_usa : "";

        // Add suffix if both exist..
        if (_can && _us) {
            _can = _can + " CA";
            _us = _us + " US"
        }

        let _sep = (_can && _us) ? " / " : " ";


        return (
            <div style={{ "fontWeight": "600" }}>{(window.sitesettings.is_canadian && (_can || _us)) ? <>{_can}{_sep}{_us}</> : <>{_us}{_sep}{_can}</>}<small><nobr> list price</nobr></small></div>
        )

    }

    const columns = [

        {
            title: <div style={{ "paddingLeft": "2px" }}><small>ISBN</small></div>, dataIndex: 'isbn', key: 'isbn', render: (e) => {
                return (<div style={{ "width": "93px", "textAlign": "center", "paddingLeft": "2px" }} className='overhide'>{e}</div>)
            }
        },
        {
            title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return <div className='overhide' style={{ "width": "190px" }} ><a style={{ "cursor": "pointer" }} onClick={() => expandCell(f)}>{e}{(f.subtitle) ? " : " + f.subtitle : ""}</a></div>
            }
        },
        {
            title: <small>Author</small>, style: { "overflow": "hidden" }, dataIndex: 'authors', key: 'authors', render: (e) => {
                return <div style={{ "width": "95px" }} className='overhide'>{e.map(item => item.name).join(",")}</div>
            }
        },
        {
            title: <small>Bd</small>, dataIndex: 'binding_code', key: 'binding_code', render: (e) => {
                return <div style={{ "width": "30px" }} className='overhide'>{e}</div>
            }
        },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {
                
                return drawPrice(f.list_price_can, f.list_price_usa);
                return (
                    <>
                        {f.available.map(item => {
                            return <div style={{ "width": "50px", "fontWeight": "normal", "fontStyle": (item.code) ? "italic" : "normal", "color": (item.code) ? bestsellerColor : "normal" }}>{(item.row_price > 0) ? "$" + item.row_price : ""}&nbsp;</div>
                        })}
                        {/* <div>&nbsp;</div> */}
                    </>
                );
            }
        },
        {
            title: <small>PubDate</small>, dataIndex: 'release_date', key: 'release_date', render: (e, f) => {
                return <div style={{ "width": "65px" }} className='overhide'><nobr><span className={colorDate(e, f)}>{moment(e * 1000).format("MMMD-YY")}</span></nobr></div>;
            }
        },
        {
            title: <small>Series</small>, dataIndex: 'series', key: 'series', render: (e) => {
                return (<div style={{ "width": "40px" }} className='overhide'>{e}</div>)
            }
        },
        {
            title: <small>#</small>, dataIndex: 'series_num', key: 'series_num', render: (e) => {
                return (<div style={{ "width": "15px" }} className='overhide'>{e}</div>)
            }
        },
        {
            title: <small>Subjects</small>, dataIndex: 'subjects', key: 'subjects', render: (e) => {
                return (
                    <div style={{ "width": "90px" }} className='overhide'>
                        <span>
                            {parseSubjectsWeird(e, 15)}
                        </span>
                    </div>
                );
            }
        },
        {
            title: <small>Publisher</small>, dataIndex: 'publisher', key: 'publisher', render: (e) => {
                return (<div style={{ "width": "90px" }} className='overhide'>{e}</div>)
            }
        },
        {
            title: <small>Edition</small>, dataIndex: 'edition', key: 'edition', render: (e) => {
                return (<div style={{ "width": "50px" }} className='overhide'>{e}</div>)
            }
        },
        {
            title: <small>Rank</small>, dataIndex: 'rank', key: 'rank', render: (e, f) => {
                return (<div style={{ "textAlign": "right", "width": "30px" }} className='overhide'><TitleRank highlight={f.highlight} number_only rank={e} /></div>);
            }
        },
        {
            title: <small>Cart</small>, dataIndex: 'cart', key: 'cart', render: (e, f) => {
                return (
                    <div style={{ "textAlign": "right", "width": "75px" }} className='overhide'>
                        <Overlay width={750} component={<TestOrderBox wrap data={f} isbn={f.isbn} />}>{drawQuantity(f.isbn, session.cart)}</Overlay>
                    </div>
                )
            }
        },
        {
            title: <small>OH/OO</small>, dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                return (
                    <div style={{ "width": "70px" }} className='overhide'>
                        <Space size={0}>
                            <div>
                                {f.available.map(item => {
                                    return <div style={{ "backgroundColor": successColor, "minWidth": "35px", "borderRadius": "2px 0px 0px 2px", "textAlign": "center", "color": "#fff", "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal", "margin": "0px", "marginBottom": "2px" }}>{item.onhand || ""}&nbsp;</div>
                                })}
                                {getPeerFromSales(f.conditions, true, f.highlight)}
                            </div>
                            <div>
                                {f.available.map(item => {
                                    return <div style={{ "backgroundColor": warningColor, "minWidth": "35px", "borderRadius": "0px 2px 2px 0px", "textAlign": "center", "color": "#fff", "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal", "margin": "0px", "marginBottom": "2px" }}>{item.onorder || ""}&nbsp;</div>
                                })}
                                {getPeerFromSales(f.conditions, false, f.highlight)}
                            </div>
                        </Space>
                    </div>
                );
            }
        },


    ]
    if (canSequence()) {
        columns.unshift({
            title: <small>#</small>, align: "center", className: 'cellBorder', dataIndex: 'eisbn', key: 'eisbn', render: (e, f, g) => {
                return (
                    <div style={{ "width": "20px" }} className='overhide'>
                        <div style={{ "marginLeft": "12px", "paddingTop": "-2px" }}>
                            <Bookmark index={0} searchFilters={searchFilters} isbn={f.isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} /></div>
                        <div>
                            <small>{f.sequence_num}</small>
                        </div>
                    </div>
                );
            }
        })
    }

    if (session.chosen_supplier) {

        columns.splice(1, 0, {
            title: <small>%</small>, className: "cellBorder", dataIndex: 'discount', key: 'discount', render: (e, f) => {
                return (
                    <div style={{ "width": "30px" }} className='overhide'>
                        <span className="c">{(f.hasOwnProperty("supplier_info")) ? f.supplier_info.discount : ""}</span>
                    </div>
                );
            }
        })

        columns.splice(1, 0, {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {
                return (
                    <div style={{ "width": "55px" }} className='overhide'>
                        <span className="c">{(f.hasOwnProperty("supplier_info") && f.supplier_info.price) ? "$" + f.supplier_info.price : ""}</span>
                    </div>
                );
            }
        })

        columns.splice(1, 0, {
            title: <small>OO</small>, dataIndex: 'oo', key: 'oo', render: (e, f) => {
                return (
                    <div style={{ "width": "30px" }} className='overhide'>
                        <nobr><span className="c">{(f.hasOwnProperty("supplier_info")) ? convertSupplierCode(f.supplier_info.onorder) : ""}</span></nobr>
                    </div>
                );
            }
        })

        columns.splice(1, 0, {
            title: <small>OH</small>, dataIndex: 'oh', key: 'oh', render: (e, f) => {
                return (
                    <div style={{ "width": "30px" }} className='overhide'>
                        <nobr><span className="c">{(f.hasOwnProperty("supplier_info")) ? convertSupplierCode(f.supplier_info.onhand) : ""}</span></nobr>
                    </div>
                );
            }
        })
    } else {
        columns.push(
            {
                title: <small>History</small>, width: "150px", dataIndex: 'sales', key: 'sales', render: (e, f) => {
                    return (<SalesSummary conditions={f.conditions} highlight={f.highlight} />)
                }
            }
        )
    }

    const getPeerFromSales = (_sales, _ohhand = true, highlight = false) => {
        if (_sales.length > 0 && _sales[0].hasOwnProperty("peer")) {
            if (_ohhand) {
                return <small className="rowRawData" style={{ "whiteSpace": "nowrap" }}>{_sales[0].peer.onhand || ""}<sup> {_sales[0].peer.onhandlocs || ""} </sup>&nbsp;</small>;
            } else {
                return <small className="rowRawData" style={{ "whiteSpace": "nowrap" }}>{_sales[0].peer.onorder || ""}<sup> {_sales[0].peer.onorderlocs || ""}</sup>&nbsp;</small>;
            }
        }

        return (<></>);

    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "marginTop": "-3px", "marginLeft": "-3px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <div className='shim' /><div className='shim' /><div className='shim' />
                        <Renderer close={false}><ItemLayouts cart_update data={_record} display="details2" /></Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.eisbn)) {
            setExpanded([_data.eisbn]);
        } else {
            setExpanded([]);
        }
    }

    const routePathToAnalytics = () => {
        window.open("/reports/analytics/filter/v/" + toUriHash(objectToPath(searchFilters).replace("/filter", "")), "_blank")
    }

    const displayGroup = () => {
        return (
            <div style={{ "marginTop": "-7px" }}>
                <Space>
                    <Radio.Group size="small" value={display} onChange={(e) => {
                        Cookies.set("browse_show_list", e.target.value);
                        if (searchFilters.x != null) {
                            getBrowse();
                        }
                        setDisplay(e.target.value)
                    }}>
                        {/* <Radio.Button value={false}><UnorderedListOutlined /></Radio.Button>
            <Radio.Button value={true}><PicCenterOutlined /></Radio.Button> */}
                        <Radio.Button value={"details2"}><small><PicLeftOutlined /></small></Radio.Button>
                        <Radio.Button value={"list"}><small><MenuOutlined /></small></Radio.Button>
                        <Radio.Button value={"cards"}><small><AppstoreOutlined /></small></Radio.Button>
                        <Radio.Button value={"calendar"}>
                            <Tooltip title={<small>Compare previous edition side-by-side. <br />(Best for calendar purchasing)</small>}><small><CalendarOutlined /></small></Tooltip>
                        </Radio.Button>
                    </Radio.Group>

                </Space>
            </div>

        )
    }

    // const getTerm = () => {
    //     if (history.location.pathname.includes("/t/")) {

    //         let _arr = history.location.pathname.split("/t/");
    //         if (_arr.length > 1) {
    //             let _spt = _arr[1].split("/");
    //             if (_spt.length > 0) {
    //                 return _spt[0];
    //             } else {
    //                 return _arr[1];
    //             }
    //         }

    //     } else {
    //         return "";
    //     }
    // }

    const [form] = Form.useForm();


    const SearchWithin = () => {


        const [val, setVal] = useState((searchFilters.hasOwnProperty("t") ? searchFilters.t : ""));

        useEffect(() => {
            setVal(searchFilters.t)
        }, [searchFilters.t])
        return (
            <Form.Item noStyle initialValue={(searchFilters.hasOwnProperty("t") ? searchFilters.t : "")} label={<small>Find a list</small>}>
                <Input.Search size="small" onChange={(e) => setVal(e.target.value)} onSearch={(e, f) => {
                    setVal(e);
                    if (!e && f.type !== "click" && f.type !== "keydown") {
                        return;
                    }
                    e = handleTermSlash(e, true)
                    let sf = { ...searchFilters, "t": e, "o": 0 }
                    setSearchFilters(sf);
                    getBrowse(false, sf);
                    setCurrent(1);
                    history.push({ pathname: path + objectToPath(sf) });
                }} allowClear value={val} enterButton placeholder="Search within..." style={{ "width": "324px" }} />
            </Form.Item>

        )


    }

    const drawFilters = () => {

        return (
            <div className="layoutHeader" style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px", "paddingTop": "0px" }}>
                <Form form={form} layout="vertical">
                    <div className="float-flex">
                        <Space wrap size={[5, 0]}>
                            <SearchWithin />
                            <conditional.true value={(display === "list")}>
                                <Form.Item noStyle label={<small>Pubstock</small>}>
                                    <Tooltip title={"Choose a supplier to add their availability to the list of titles below"}>
                                        <Select showSearch size="small" onChange={(e) => setSupplier(e)} value={session.chosen_supplier} style={{ width: 95 }}>
                                            <Select.Option value=""><small style={{ "color": "#bbb" }}>Pubstock...</small></Select.Option>
                                            {window.sitesettings.suppliers.map(item => {
                                                return <Select.Option key={item} value={item}><small>{item}</small></Select.Option>
                                            })}
                                        </Select>
                                    </Tooltip>
                                </Form.Item>
                            </conditional.true>

                            <conditional.true value={(display !== "list")}>
                                <Form.Item noStyle label={<small>Always show</small>}>
                                    <div className="ant-radio-group-solid ant-radio-group-small">
                                        <Radio.Button size="small" style={{ "padding": "0px 12px" }} onClick={(e) => setPrefs({ ...prefs, "bm_notes": !prefs.bm_notes })} checked={prefs.bm_notes} buttonStyle="solid"><small>{(!prefs.bm_notes) ? <PushpinOutlined /> : <PushpinFilled />}&nbsp; BM Data</small></Radio.Button>
                                    </div>
                                </Form.Item>
                            </conditional.true>

                            <Form.Item noStyle label={<small>ISBNs</small>}>
                                <CopyISBNs searchFilters={searchFilters} />
                            </Form.Item>

                            <Form.Item noStyle label={<small>List</small>}>
                                <ListInclude path={path} searchFilters={searchFilters} setSearchFilters={setSearchFilters} getBrowse={getBrowse} />
                            </Form.Item>

                            <Tooltip title={<small>View results in Analytics</small>}><Button onClick={() => routePathToAnalytics()} type="primary" size="small"><small><SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} /> View Analytics</small></Button></Tooltip>
                            <Refine path={path} getBrowse={getBrowse} searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
                        </Space>

                        <div>
                            <Space>
                                <div><small>Sort:</small></div>
                                <Form.Item noStyle label={<small>Sort</small>}>
                                    <Select value={(searchFilters.v) ? searchFilters.v : ""} size="small" onChange={(e) => {
                                        let _f = { ...searchFilters, "v": e }

                                        // Set default sort if not sequence
                                        if (e !== "sequence") {
                                            Cookies.set("default_search_sort", e);
                                        }


                                        setSearchFilters(_f);
                                        getBrowse(false, _f);
                                        history.push({ pathname: path + objectToPath(_f) })
                                    }} style={{ width: 110 }}>
                                        {((searchFilters.hasOwnProperty("x") && (searchFilters)) && <Select.Option key="sequence" value="sequence"><small>List Order</small></Select.Option>)}
                                        <Select.Option key="popularity" value=""><small>Popularity</small></Select.Option>
                                        <Select.Option key="instore" value="in_store"><small>In Store</small></Select.Option>
                                        <Select.Option key="date" value="date" ><small>Date</small></Select.Option>
                                        <Select.Option key="title" value="title" ><small>Title</small></Select.Option>
                                        <Select.Option key="author" value="author" ><small>Author</small></Select.Option>
                                        {((searchFilters.v && searchFilters.v.substring(0, 7) === "related") && <Select.Option key={searchFilters.v} value={searchFilters.v} ><small>Related</small></Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Space>
                        </div>

                    </div>
                </Form>
            </div>
        )
    }


    function rowClasses(record, index) {
        if (record.highlight) {
            return "rowHighlightHover defaultCursor highlightRow"
        }
        return "rowHover defaultCursor"
    }

    function drawListTable() {
        return (

            <div style={{ "margin": "0 -20px" }}>
                {/* <Spin style={{ "minHeight": "calc(100vh - 470px)" }} spinning={loading}> */}
                {(!loading &&
                    <Table
                        size="small"
                        pagination={false}
                        columns={columns}
                        rowKey={"eisbn"}
                        rowClassName={(record, index) => rowClasses(record, index)}
                        className="fullTable"
                        dataSource={results.rows}
                        expandable
                        expandedRowKeys={expanded}
                        expandIconColumnIndex={-1}
                        onExpand={(e) => { return false; }}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={((record) => drawChildTable(record))}
                    />
                )}
                {/* </Spin> */}
            </div>
        )
    }

    return (
        <>
            <Layout className="layout" style={{ "minHeight": "500px" }}>
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                        <div style={{ "float": "right" }}>{displayGroup()}</div>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                            <div>{getHeader()}</div>
                        </Typography.Title>
                        <div className="shim" />
                        <Divider dashed style={{ "margin": "0px 0px" }} />
                    </div>
                    {(initLoaded && <>
                        <BrowseFilters5 context={(results["context"]) || { name: "" }} setToolboxes={setToolboxes} display_mode={display} block setTitle={setTitle} listLabel={listLabel} loading={loading} getBrowse={getBrowse} toolboxes={results.toolboxes} path={path} setCurrent={setCurrent} filters={filters} setFilters={setFilters} searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
                        <div className="trans" style={{ "opacity": (loading) ? "0.5" : 1 }}> {drawFilters()} </div>
                    </>)}
                    {/* 
                    {(!loading && <>
                        <BrowseFilters5 getBrowse={getBrowse} toolboxes={results.toolboxes} path={path} display={displayGroup()} setCurrent={setCurrent} filters={filters} searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
                        {drawFilters()}
                    </>)} */}
                    <Paginate
                        save={"browse_limit"}
                        loading={loading}
                        max_offset={results.max_offset}
                        paginate={{
                            current: current,
                            offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20
                        }}
                        setPaginate={(e) => {
                            setCurrent(e.current);
                            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                            setSearchFilters(sf);
                            getBrowse(false, sf);
                            history.push({ pathname: path + objectToPath(sf) });
                        }} count={results.row_count} />
                    <Spin style={{ "minHeight": "calc(100vh - 470px)" }} spinning={loading}>
                        <div className="bcg2" style={{ "padding": "0px 20px", "minHeight": "calc(100vh - 440px)" }}>
                            <conditional.true value={(display === "calendar")}>
                                <div style={{ "display": "flex", "justifyContent": "space-around" }}>
                                    <div>
                                        <small>Current Edition</small>
                                    </div>
                                    <div>
                                        <small>Previous Edition</small>
                                    </div>
                                </div>
                                <Divider style={{ "margin": "0px" }} />
                            </conditional.true>
                            <conditional.true key={"list"} value={(display === "list")}>
                                {drawListTable()}
                            </conditional.true>
                            <conditional.true key={"notlist"} value={(display !== "list")}>
                                <br />
                                {/* Split into chunks so renderer can draw placeholder */}
                                {/* The card/grid view of the ISBNs */}
                                <conditional.true key={"cards"} value={(display === "cards")}>
                                    <div>
                                        {results.rows.reduce((all, one, i) => {
                                            const ch = Math.floor(i / 5);
                                            all[ch] = [].concat((all[ch] || []), one);
                                            return all
                                        }, []).map((item, index) => {
                                            return <div key={item[0].isbn + "cards"}>
                                                <Bookmark index={index} searchFilters={searchFilters} isbn={item[0].isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} />
                                                <Renderer>
                                                    <ItemLayouts data={item} index={index} display={display} />
                                                </Renderer>
                                                <Divider dashed />
                                            </div>
                                        })}
                                    </div>
                                </conditional.true>
                                {/* The Details view of the ISBNs */}
                                <conditional.true key={"notcards"} value={(display !== "cards")}>
                                    <div>
                                        {results.rows.map((item, index) => {
                                            return <div key={item.eisbn + "notcards"}>
                                                <Bookmark index={index} searchFilters={searchFilters} isbn={item.isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} />
                                                {/* <LazyLoad offset={500} once={true} height={360}>
                                                    <div key={item.eisbn + "ll"}>
                                                        {(canSequence() &&
                                                            <div className="shim" style={{ "margin": "25px -20px", "marginTop": "0px" }}>
                                                                <Divider style={{ "marginTop": "0px" }} orientation="left" plain><small className="c" style={{ "fontWeight": "600" }}>#{(item.sequence_num)}</small></Divider>
                                                            </div>
                                                        )}
                                                        <Renderer close={false}>
                                                            <ItemLayouts data={item} display={display} />
                                                        </Renderer>
                                                        <div className="borderBottom shim" style={{ "margin": "35px -20px", "borderBottomWidth": "6px" }} />
                                                    </div>
                                                </LazyLoad> */}
                                                <LazyLoad offset={500} once={true} height={360}>
                                                    <div key={item.eisbn + "ll"}>
                                                        <Renderer close={false}>
                                                            <ItemLayouts
                                                                actual_id={results?.context?.list_id}
                                                                data={item}
                                                                display={display}
                                                                contextValues={results.context}
                                                            />
                                                        </Renderer>
                                                    </div>
                                                    <div className="borderBottom shim" style={{ "margin": "35px -20px", "borderBottomWidth": "6px" }} />
                                                </LazyLoad>
                                            </div>
                                        })}
                                    </div>
                                </conditional.true>
                            </conditional.true>
                        </div>
                    </Spin>
                    <Divider className="bc" style={{ "margin": "0" }} />
                    <Paginate
                        save={"browse_limit"}
                        loading={loading}
                        max_offset={results.max_offset}
                        paginate={{
                            current: current,
                            offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0,
                            pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20
                        }}
                        setPaginate={(e) => {
                            setCurrent(e.current);
                            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                            setSearchFilters(sf);
                            getBrowse(false, sf);
                            history.push({ pathname: path + objectToPath(sf) });
                        }}
                        count={results.row_count}
                    />
                </Layout.Content>
            </Layout>
        </>
    )
}



